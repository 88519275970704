import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  Button,
  Link, // Import Link for the hyperlink
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import "./style.css";
import { alpha, styled } from "@mui/material/styles";
import axios from "axios";
import ThankyouCard from "../ThankyouCard/ThankyouCard";
import { createEnquiry } from "../../apollo/createEnquiry";
import { updateEnquiry } from "../../apollo/updateEnquiry";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

interface Enquiry {
  id: number;
  name: string;
  phone: string;
  is_otp_verified: number;
  created_at: string | null;
  updated_at: string | null;
  __typename: string;
}

const OTPModal: React.FC<{
  open: boolean;
  onClose: () => void;
  mobileNumber: string;
  name: string;
}> = ({ open, onClose, mobileNumber, name }) => {
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [resendTimer, setResendTimer] = useState(180);
  const [showResendLink, setShowResendLink] = useState(false);
  const [otpIncorrect, setotpIncorrect] = useState(false);
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(open);
  const [createEnquiryRes, setCreateEnquiryRes] = useState<Enquiry>({
    id: 0,
    name: "",
    phone: "",
    is_otp_verified: 0,
    created_at: null,
    updated_at: null,
    __typename: "",
  });
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();




  useEffect(() => {
    // Fetch OTP when component mounts
    const fetchOtp = async () => {
      const deviceId = sessionStorage.getItem("deviceId");
      const uniqueDeviceId = sessionStorage.getItem("deviceId");
      const devicePlatform = sessionStorage.getItem("devicePlatform");

      try {
        const existingData = await getEnquiryDataUsingMobileNumber(
          mobileNumber
        );
        if (existingData) {
          setCreateEnquiryRes(existingData);
        } else {
          const createEnquiryApi: any = await createEnquiry(
            name,
            mobileNumber,
            0
          );
          setCreateEnquiryRes(createEnquiryApi);
        }
        const response = await axios.post(
          `${process.env.REACT_APP_OTP_END_POINT}/get-otp`,
          {
            phone: mobileNumber,
            device_id: deviceId,
            unique_device_id: uniqueDeviceId,
            device_type: devicePlatform,
            origin: 2, // Send origin as a query parameter
          }
        );
      } catch (error) {
        console.error("Error fetching OTP:", error);
      }
    };

    if (open) {
      fetchOtp(); // Fetch OTP only when the modal is open
      const timer = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setShowResendLink(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setShowResendLink(false); // Hide resend link when modal is closed
    }
  }, [open, mobileNumber]);

  const handleResendOtp = () => {
    const fetchOtp = async () => {
      const deviceId = sessionStorage.getItem("deviceId");
      const uniqueDeviceId = sessionStorage.getItem("deviceId");
      const devicePlatform = sessionStorage.getItem("devicePlatform");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_OTP_END_POINT}/get-otp`,
          {
            phone: mobileNumber,
            device_id: deviceId,
            unique_device_id: uniqueDeviceId,
            device_type: devicePlatform,
            origin: 2,
          }
        );
      } catch (error) {
        console.error("Error fetching OTP:", error);
      }
    };
    fetchOtp();
    setShowResendLink(false); // Hide resend link
    setResendTimer(180);
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setShowResendLink(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  };

  const handleOtpChange = (index: number, value: string) => {
    if (value === "" || /^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Only move focus for single-digit entry, not on pasting multiple digits
      if (value !== "" && value.length === 1 && index < otp.length - 1) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }

      // Move focus to the previous input if backspace is pressed
      if (value === "" && index > 0) {
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) prevInput.focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    const deviceId = sessionStorage.getItem("deviceId");
    const uniqueDeviceId = sessionStorage.getItem("deviceId");
    const devicePlatform = sessionStorage.getItem("devicePlatform");
    createCustomerEventLog({
      event_name: `clicked_on_verify_otp_${isMobile ? "mobile" : "desktop"}`,
      event_type: "click_event"
    })
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_OTP_END_POINT}/verify-otp`,
        {
          whatsapp_consent: true, // Set this to true or false based on your requirements
          phone: mobileNumber,
          otp: otp.join(""),
          device_id: deviceId,
          unique_device_id: uniqueDeviceId,
          device_type: devicePlatform,
          origin: 2,
          name,
        }
      );
      createEnquiryRes &&
        createEnquiryRes.id &&
        (await updateEnquiry(createEnquiryRes.id, name, 1));
      setShowOTPModal(false);
      setShowThankyouModal(true);
      createCustomerEventLog({
        event_name: `verify_otp_success_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event"
      })
    } catch (error) {
      setotpIncorrect(true);
      setOtp(["", "", "", ""]);
      console.error("Error verifying OTP:", error);
      createCustomerEventLog({
        event_name: `verify_otp_failed_${isMobile ? "mobile" : "desktop"}`,
        event_type: "click_event"
      })
    }
  };

  const closeModal = (type: string) => {
    if (type === "editMobileNumber") {
      onClose();
    }
  };

  const minutes = Math.floor(resendTimer / 60);
  const seconds = resendTimer % 60;

  return (
    <>
      {showOTPModal ? (
        <Dialog
          open={open}
          onClose={onClose}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "352px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "349px",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <IconButton
              onClick={async () => {
                onClose();
                createCustomerEventLog({
                  event_name:`otp_popup_closed_${ isMobile ? "mobile":"desktop"}`,
                  event_type:"click_event"
                })
              }}
              sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
            >
              <CloseIcon />
            </IconButton>

            {/* Modal Content */}
            <DialogContent>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Enter OTP
              </Typography>
              <Typography
                textAlign="left"
                mt={1}
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                }}
              >
                We’ve sent a 4-digit code to
              </Typography>
              <Box sx={{ display: "flex", width: { xs: "100%" } }}>
                <Typography
                  textAlign="left"
                  fontWeight="bold"
                  sx={{ width: "40%" }}
                >
                  {mobileNumber}
                </Typography>
                <Box
                  onClick={() => closeModal("editMobileNumber")}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "left",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#631041",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "normal",
                      width: "60%",
                      display: "flex",
                      justifyContent: "center",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    fontWeight="bold"
                  >
                    Edit number
                  </Typography>
                  <Box
                    sx={{
                      width: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="13"
                      viewBox="0 0 14 13"
                      fill="none"
                    >
                      <path
                        d="M1.66699 11.6093H12.3337M1.66699 11.6093V8.8718L7.00033 3.39683M1.66699 11.6093H4.33366L9.66699 6.13431M7.00033 3.39683L8.91273 1.43359L8.91393 1.43243C9.17713 1.16218 9.30899 1.02682 9.46099 0.976124C9.59493 0.931461 9.73912 0.931461 9.87306 0.976124C10.0249 1.02679 10.1567 1.16199 10.4195 1.43185L11.5794 2.62253C11.8434 2.89355 11.9755 3.02913 12.0249 3.18539C12.0685 3.32284 12.0684 3.4709 12.0249 3.60835C11.9755 3.7645 11.8437 3.89987 11.58 4.17051L11.5794 4.17109L9.66699 6.13431M7.00033 3.39683L9.66699 6.13431"
                        stroke="#631041"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-around" mt={3} mb={2}>
                {otp.map((digit, index) => (
                  <TextField
                    type="number"
                    autoComplete="off"
                    key={index}
                    id={`otp-input-${index}`} // Assigning a unique id for each input
                    variant="outlined"
                    value={digit}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        borderRadius: "8px",
                        border: "1px solid rgba(0, 0, 0, 0.20)",
                        background: "rgba(255, 255, 255, 0.05)",
                        width: "48px",
                        height: "48px",
                        padding: "5px 8px",
                        fontSize: "22px",
                        textAlign: "center", // Centering the text
                      },
                      onPaste: (e) => {
                        const pastedData = e.clipboardData
                          .getData("text")
                          .trim();
                        if (pastedData.length === otp.length) {
                          // Ensure the pasted data matches the OTP length
                          const otpArray = pastedData.split("");

                          setOtp(otpArray); // Set the entire OTP state at once

                          otpArray.forEach((digit, i) => {
                            const input = document.getElementById(
                              `otp-input-${i}`
                            ) as HTMLInputElement;
                            if (input) input.value = digit; // Update each input with the pasted value
                          });

                          // Focus on the last input field
                          const lastInput = document.getElementById(
                            `otp-input-${otpArray.length - 1}`
                          );
                          if (lastInput) lastInput.focus();

                          e.preventDefault(); // Prevent default paste behavior
                        }
                      },
                      onKeyDown: (e) => {
                        if (e.key === "Backspace" && !otp[index]) {
                          const prevInput = document.getElementById(
                            `otp-input-${index - 1}`
                          ) as HTMLInputElement;
                          if (prevInput) prevInput.focus();
                        }
                      },
                    }}
                  />
                ))}
              </Box>

              {/* Resend Timer */}
              <Typography
                textAlign="left"
                mt={1}
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {!otpIncorrect && resendTimer > 0 ? (
                  `Didn’t receive OTP? Resend in ${`${minutes.toString().padStart(2, "0")}:${seconds
                    .toString()
                    .padStart(2, "0")}`}`
                ) : !otpIncorrect ? (
                  <>
                    Didn’t receive OTP?{" "}
                    <Link
                      component="button"
                      onClick={handleResendOtp}
                      sx={{
                        color: "#631041",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: 500,
                        textDecoration: "underline",
                      }}
                    >
                      Resend
                    </Link>
                  </>
                ) : null}
              </Typography>
              {otpIncorrect ? (
                <Typography
                  sx={{
                    color: "#EF2965",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Please enter a valid OTP
                </Typography>
              ) : null}
              {/* Verify OTP Button */}
              <Box textAlign="center" mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={otp.some((digit) => !digit)}
                  onClick={handleVerifyOtp}
                  sx={{
                    fontFamily: "Poppins",
                    display: "flex",
                    width: "100%",
                    height: "48px",
                    padding: "10px 24px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                    flexShrink: 0,
                    borderRadius: "12px",
                    border: `1px solid ${otp.some((digit) => !digit) ? "" : "#B37839"
                      }`,
                    background: otp.some((digit) => !digit)
                      ? "#E6E6E6"
                      : "#631041",
                  }}
                >
                  Verify OTP
                </Button>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      ) : (
        <ThankyouCard open={showThankyouModal} onClose={() => onClose()} />
      )}
    </>
  );
};

export default OTPModal;
