import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  InputAdornment,
  Badge,
  TextField,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./style.css";
import CustomCssTextField from "../CustomCssTextField/CustomCssTextField";
import OTPModal from "../OTPModal/OTPModal";
import { LocationSvgBlack } from "./LocationSvgBlack";
import ThankyouCardForExisting from "../ThankyouCardForExisting/ThankyouCardForExisting";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";

const BannerForm: React.FC = () => {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [isThankyouCardForExisting, setIsThankyouCardForExisting] =
    useState(false);

  const handleSubmit = async (values: any, resetForm: () => void) => {
    const existingData = await getEnquiryDataUsingMobileNumber(
      values.mobile.toString()
    );
    if (existingData && existingData.is_otp_verified) {
      resetForm();
      setIsThankyouCardForExisting(true);
    } else {
      resetForm();
      setUserName(values.name);
      setUserMobileNumber(values.mobile.toString());
      setIsOtpModalOpen(true);
    }
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Mobile number is required"),
  });

  const [focusedName, setFocusedName] = useState(false);
  const [focusedMobile, setFocusedMobile] = useState(false);
  const [value, setValue] = useState("");

  return (
    <>
      <Card
        sx={{
          maxWidth: "80%",
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          position: "absolute",
          top: "300px",
          margin: "0% 10%",
          width: "100%",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            textAlign="left"
            sx={{
              color: "#631041",
              fontFamily: "Poppins",
              fontSize: "17px",
              fontWeight: 600,
              fontStyle: "normal",
              lineHeight: "20px",
            }}
          >
            Plan Your Dream Wedding with Our Expert Help
          </Typography>
          <Typography
            variant="h6"
            textAlign="left"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              fontStyle: "normal",
              lineHeight: "normal",
              marginTop: "5%",
            }}
          >
            Our relationship manager will guide your entire wedding planning
            journey, understand your needs, find the best service providers,
            negotiate to get the best price, and you can also get exclusive
            discounts on your wedding shopping from top brands
          </Typography>
          {/* <Box
            className="location-block"
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "5%",
            }}
          >
            <Box
              className="location-icon"
              sx={{
                width: { xs: "10%", sm: "25%" },
                display: "flex",
              }}
            >
              <LocationSvgBlack />
            </Box>
            <Box
              className="location-description"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                textAlign="center"
                className="chennai"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "normal",
                }}
              >
                We’re currently available only in Chennai
              </Typography>
            </Box>
          </Box> */}
          <Box
            className="location-block"
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "5%",
            }}
          >
            <LocationSvgBlack />

            <Typography
              variant="h6"
              textAlign="center"
              className="chennai"
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: "normal",
                lineHeight: "normal",
                margin: "auto 0%",
                marginLeft:"-3%"
              }}
            >
              We’re currently available only in Chennai
            </Typography>
          </Box>
          <Formik
            initialValues={{ name: "", mobile: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              isValid,
              handleChange,
              setFieldValue,
              handleBlur,
              setFieldTouched,
            }) => (
              <Form>
                <CustomCssTextField
                  autoComplete="off"
                  onFocus={() => setFocusedName(true)}
                  onBlur={() => {
                    setFieldTouched("name", true);
                    setFocusedName(false);
                  }}
                  placeholder="Enter your name"
                  label={focusedName || values.name ? "Enter your name" : ""}
                  name="name"
                  fullWidth
                  margin="normal"
                  value={values.name}
                  onChange={handleChange}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                  }}
                />
                {touched.name && errors.name ? (
                  <Typography
                    sx={{
                      color: "#EF2965",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      height: "15px",
                    }}
                  >
                    {errors.name}
                  </Typography>
                ) : (
                  <div style={{ height: "15px" }}></div>
                )}
                <CustomCssTextField
                  autoComplete="off"
                  onFocus={() => setFocusedMobile(true)}
                  onBlur={() => {
                    setFieldTouched("mobile", true);
                    setFocusedMobile(false);
                  }}
                  label={
                    focusedMobile || values.mobile
                      ? "Enter your mobile number"
                      : ""
                  }
                  placeholder="Enter your mobile number"
                  name="mobile"
                  type="number"
                  fullWidth
                  margin="normal"
                  value={values.mobile}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setFieldValue("mobile", value); // Use setFieldValue to update form state
                    }
                  }}
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "normal",
                    borderRadius: "8px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                          }}
                        >
                          +91
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                {touched.mobile && errors.mobile ? (
                  <Typography
                    sx={{
                      color: "#EF2965",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      height: "15px",
                    }}
                  >
                    {errors.mobile}
                  </Typography>
                ) : (
                  <div style={{ height: "15px" }}></div>
                )}

                <Box textAlign="center" mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{
                      textTransform: "none",
                      display: "flex",
                      width: "100%",
                      height: "48px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      border: `1px solid #B37839`,
                      background: "#631041",
                      color: "#FFF",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                    }}
                  >
                    Request call back
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                    >
                      <path
                        d="M9.5 6L15.5 12L9.5 18"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
      {isOtpModalOpen ? (
        <OTPModal
          open={isOtpModalOpen}
          onClose={() => setIsOtpModalOpen(false)}
          name={userName}
          mobileNumber={userMobileNumber}
        />
      ) : isThankyouCardForExisting ? (
        <ThankyouCardForExisting
          open={true}
          onClose={() => setIsThankyouCardForExisting(false)}
        />
      ) : null}
    </>
  );
};

export default BannerForm;
