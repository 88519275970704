import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import "./style.css";
import CustomCssTextField from "../CustomCssTextField/CustomCssTextField";
import OTPModal from "../OTPModal/OTPModal";
import ThankyouCardForExisting from "../ThankyouCardForExisting/ThankyouCardForExisting";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";

// Validation schema using Yup
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
    .required("Mobile number is required"),
});

const EnterYourDetailsModal: React.FC<{
  open: boolean;
  onClos: () => void;
}> = ({ open, onClos }) => {
  const [focusedNameE, setFocusedNameE] = useState(false);
  const [focusedMobileE, setFocusedMobileE] = useState(false);

  const [userName, setUserName] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");

  const [isFormModalOpen, setIsFormModalOpen] = useState(true);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [isThankyouCardForExisting, setIsThankyouCardForExisting] =
    useState(false);

  const handleSubmit = async (values: any) => {
    const existingData = await getEnquiryDataUsingMobileNumber(
      values.mobile.toString()
    );
    if (existingData && existingData.is_otp_verified) {
      onClos();
      // Close the form modal and show thank you card for existing users
      setIsThankyouCardForExisting(true);
      setIsFormModalOpen(false); // Close form modal
    } else {
      onClos();
      // Set user details and open OTP modal
      setUserName(values.name);
      setUserMobileNumber(values.mobile.toString());
      setIsOtpModalOpen(true); // Open OTP modal
      setIsFormModalOpen(false); // Close form modal
    }
  };

  return (
    <>
      {isOtpModalOpen ? (
        <OTPModal
          open={isOtpModalOpen}
          onClose={() => setIsOtpModalOpen(false)}
          name={userName}
          mobileNumber={userMobileNumber}
        />
      ) : isFormModalOpen ? (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "352px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "340px",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <IconButton
              onClick={onClos}
              sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
            >
              <CloseIcon />
            </IconButton>
            {/* Modal Content */}
            <DialogContent>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Enter your details
              </Typography>
              <Formik
                initialValues={{ name: "", mobile: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  setFieldTouched,
                }) => (
                  <Form>
                    <DialogContent sx={{ padding: "0%" }}>
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedNameE(true)}
                        onBlur={() => {
                          setFieldTouched("name", true);
                          setFocusedNameE(false);
                        }}
                        placeholder="Enter your name"
                        label={
                          focusedNameE || values.name ? "Enter your name" : ""
                        }
                        name="name"
                        fullWidth
                        margin="normal"
                        value={values.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("name", value); // Use setFieldValue to update form state
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      {touched.name && errors.name ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.name}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedMobileE(true)}
                        onBlur={() => {
                          setFieldTouched("mobile", true);
                          setFocusedMobileE(false);
                        }}
                        label={
                          focusedMobileE || values.mobile
                            ? "Enter your mobile number"
                            : ""
                        }
                        placeholder="Enter your mobile number"
                        name="mobile"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={values.mobile}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^\d*$/.test(value) && value.length <= 10) {
                            setFieldValue("mobile", value); // Use setFieldValue to update form state
                          }
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                +91
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.mobile && errors.mobile ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.mobile}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}

                      <Box textAlign="center" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !isValid ||
                            !values.name.length ||
                            !values.mobile.toString().length
                          } // Disable if form is invalid or fields aren't touched
                          type="submit"
                          sx={{
                            fontFamily: "Poppins",
                            textTransform: "none",
                            display: "flex",
                            width: "100%",
                            height: "48px",
                            padding: "12px 24px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            flexShrink: 0,
                            borderRadius: "12px",
                            border: `1px solid ${
                              isValid &&
                              values.name.length &&
                              values.mobile.length
                                ? "#B37839"
                                : ""
                            }`, // Use different colors for valid/invalid
                            background:
                              isValid &&
                              values.name.length &&
                              values.mobile.toString().length
                                ? "#631041"
                                : "#E6E6E6", // Background color based on validity and touched state
                          }}
                        >
                          Get OTP
                        </Button>
                      </Box>
                    </DialogContent>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Box>
        </Dialog>
      ) : isThankyouCardForExisting ? (
        <ThankyouCardForExisting
          open={true}
          onClose={() => setIsThankyouCardForExisting(false)}
        />
      ) : (
        <Dialog
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Add borderRadius to the Paper element
              margin: "unset",
              width: "352px",
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "340px",
              width: "100%",
              maxWidth: "352px",
            }}
          >
            {/* Close button */}
            <IconButton
              onClick={onClos}
              sx={{ position: "absolute", top: 8, right: 8, color: "#000000" }}
            >
              <CloseIcon />
            </IconButton>
            {/* Modal Content */}
            <DialogContent>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "24px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                }}
              >
                Enter your details
              </Typography>
              <Formik
                initialValues={{ name: "", mobile: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <Form>
                    <DialogContent sx={{ padding: "0%" }}>
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedNameE(true)}
                        onBlur={() => {
                          setFieldTouched("name", true);
                          setFocusedNameE(false);
                        }}
                        placeholder="Enter your name"
                        label={
                          focusedNameE || values.name ? "Enter your name" : ""
                        }
                        fullWidth
                        margin="normal"
                        value={values.name}
                        onChange={(e) => {
                          const { value } = e.target;
                          setFieldValue("name", value); // Use setFieldValue to update form state
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                        }}
                      />
                      {touched.name && errors.name ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.name}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <CustomCssTextField
                        autoComplete="off"
                        onFocus={() => setFocusedMobileE(true)}
                        onBlur={() => {
                          setFieldTouched("mobile", true);
                          setFocusedMobileE(false);
                        }}
                        label={
                          focusedMobileE || values.mobile
                            ? "Enter your mobile number"
                            : ""
                        }
                        placeholder="Enter your mobile number"
                        name="mobile"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={values.mobile}
                        onChange={(e) => {
                          const { value } = e.target;
                          if (/^\d*$/.test(value) && value.length <= 10) {
                            setFieldValue("mobile", value); // Use setFieldValue to update form state
                          }
                        }}
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "normal",
                          borderRadius: "8px",
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                sx={{
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: "400",
                                  lineHeight: "normal",
                                }}
                              >
                                +91
                              </Typography>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {touched.mobile && errors.mobile ? (
                        <Typography
                          sx={{
                            color: "#EF2965",
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            height: "15px",
                          }}
                        >
                          {errors.mobile}
                        </Typography>
                      ) : (
                        <div style={{ height: "15px" }}></div>
                      )}
                      <Box textAlign="center" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={
                            !isValid ||
                            !values.name.length ||
                            !values.mobile.toString().length
                          } // Disable if form is invalid or fields aren't touched
                          type="submit"
                          sx={{
                            fontFamily: "Poppins",
                            textTransform: "none",
                            display: "flex",
                            width: "100%",
                            height: "48px",
                            padding: "12px 24px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            flexShrink: 0,
                            borderRadius: "12px",
                            border: `1px solid ${
                              isValid &&
                              values.name.length &&
                              values.mobile.length
                                ? "#B37839"
                                : ""
                            }`, // Use different colors for valid/invalid
                            background:
                              isValid &&
                              values.name.length &&
                              values.mobile.toString().length
                                ? "#631041"
                                : "#E6E6E6", // Background color based on validity and touched state
                          }}
                        >
                          Get OTP
                        </Button>
                      </Box>
                    </DialogContent>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default EnterYourDetailsModal;
