import { Box, Container, Typography } from "@mui/material";
import React from "react";
import TitlebarImageList from "../TitleBarWithImg/TitleBarWithImg";
import "./style.css"
import TitleBarForMobile from "../TitleBarWithImg/TitleBarForMobile";

const OurService = () => {
  return (
    <>
      <Container sx={{ marginTop: "50px" }}>
        <Box>
          <Typography
          className="title-text"
            variant="h5"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: {
                xs:"20px",
                md:"40px"
              },
              fontStyle: "normal",
              fontWeight: "500",
              marginLeft:{
                xs:"4px",
                md:"16px"
              }
              // lineHeight: "160%" /* 64px */,
            }}
          >
            Our Services
          </Typography>
        </Box>
        <div className="desktop">
        <TitlebarImageList />
        </div>
        <div className="mobile">
        <TitleBarForMobile/>
        </div>
      </Container>
    </>
  );
};

export default OurService;
