import { Typography } from "@mui/material";


const PriceBannerSection = () => {
  return (
    <>
      <Typography
        variant="h3"
        sx={{
          color: "#000",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: { xs: "14px", sm: "18px" },
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "normal",
          pl:2,
          pr:2,
          margin:{
            md:"-10px 0 36px 0",
            xs:"0 0 46px 0",
            // sm:"0 0 40px 0"
          }
        }}
      >
        Prices start from{" "}
        <span
          style={{
            fontFamily: "Roboto",
            color: "#000",
            textAlign: "center",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          ₹
        </span>
        999 for any single category, or{" "}
        <span
          style={{
            fontFamily: "Roboto",
            color: "#000",
            textAlign: "center",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          ₹
        </span>
        3999 for 4 or more categories
      </Typography>
    </>
  );
};

export default PriceBannerSection;
