import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import appLogo from "../../assets/images/appLogo.svg";
import { HeadPhoneSvg } from "../Header/HeadPhoneSvg";
import { FacebookSvg } from "./FacebookSvg";
import { InstagramSvg } from "./InstagramSvg";
import CustomContainer from "../CustomContainer/CustomContainer";
import { Link, useNavigate } from "react-router-dom";
import useCustomerEventLogs from "../../customHook/useCustomerEventLogs";
import useMobile from "../../customHook/useMobile";

const Footer = () => {
  const navigate = useNavigate();
  const { createCustomerEventLog } = useCustomerEventLogs();
  const isMobile = useMobile();

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        background: "#FFF6FC",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "80%" },
          margin: "auto",
        }}
      >
        <Box
          sx={{
            mt: 3,
            height: "100%",
            paddingTop: "4%",
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ width: { xs: "55%", sm: "30%", md: "18%" } }}>
            <img
              src={appLogo}
              alt="logo"
              width={"100%"}
              style={{ cursor: "pointer" }}
              onClick={handleLogoClick}
            />
                                <Typography sx={{marginTop:"-5%",
                      color: "#631041",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal"
                    }}>by matrimony.com group</Typography>
          </Box>
          <Box sx={{ width: { xs: "60%", md: "75%", lg: "65%" } }}>
            <Box sx={{ display: "flex", justifyContent: { xs: "end" } }}>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  width: { md: "45%", lg: "40%" },
                  justifyContent: "end",
                }}
              >
                <Link
                  to="/terms-and-conditions"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: "underline",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Terms & conditions
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  width: { md: "40%", lg: "37%" },
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to="/privacy-policy"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      textDecorationLine: "underline",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                    width: { xs: "25%", sm: "50%", lg: "20%" },
                  },
                }}
              >
                <pre
                  style={{
                    color: "#000",
                    textAlign: "right",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "normal",
                  }}
                >
                  Contact us:{" "}
                </pre>
                <pre
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  +91 8939402236
                </pre>
              </Box>

              <Box
                sx={{
                  width: { xs: "25%", md: "10%", lg: "15%" },
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://www.instagram.com/makemywedding_off?igsh=MWhjZHpyb3h3bTJyZA%3D%3D&utm_source=qr"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <InstagramSvg />
                </a>
              </Box>
              <Box
                sx={{
                  width: { xs: "25%", md: "10%", lg: "15%" },
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://www.facebook.com/profile.php?id=61565878822490"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FacebookSvg />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <Typography
          sx={{
            width: "50%",
            color: "#631041",
            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            opacity: "0.6",
            marginLeft: "5%",
            display: { xs: "none", sm: "block" },
          }}
        >
          by Matrimony.com group
        </Typography> */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: "space-between",
            width: "100%",
            marginTop: "3%",
            marginBottom: { xs: "8%", sm: "3%" },
          }}
        >
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              justifyContent: "center",
              alignItems: "center",
              width: "35%",
            }}
          >
            <a
              href="tel:+919876543210"
              style={{
                textDecoration: "none",
                display: "flex",
              }}
            >
              <HeadPhoneSvg />
              <Typography
                sx={{
                  color: "#631041",
                  textDecorationLine: "underline",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  marginLeft: "2%",
                }}
                onClick={()=>{
                  createCustomerEventLog({
                    event_name: `cliked_on_contact_us_${isMobile ? "mobile" : "desktop"}`,
                    event_type: "click_event"
                  })
                }}
              >
                Contact us
              </Typography>
            </a>
          </Box>

          <Box
            sx={{
              display: { xs: "block", lg: "none" },
              width: "36%",
            }}
          >
            <Link
              to="/terms-and-conditions"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  textDecorationLine: "underline",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Terms & conditions
              </Typography>
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "block", md: "none" },
              width: "30%",
              textAlign: "right",
            }}
          >
            <Link
              to="/privacy-policy"
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  textDecorationLine: "underline",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Box>
        </Box>

        <hr
          style={{
            opacity: "0.2",
            background: "#631041",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: "#000",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "21px",
            opacity: "0.6",
            padding: { xs: "5% 0%", sm: "2% 0%" },
          }}
        >
          © Copyright 2024 MakeMyWedding.com (Matrimony.com Group) - All Rights
          Reserved
        </Typography>
      </Box>
    </Box>
  );
};
export default Footer;
