import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import "./style.css";
import tick from "../../assets/images/tick.gif";

const ThankyouCard: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const handleOk = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Add borderRadius to the Paper element
          margin: "unset",
          width: "352px",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "298px",
          width: "100%",
          maxWidth: "352px",
        }}
      >
        {/* Modal Content */}
        <Box sx={{ margin: "0% auto" }}>
          <DialogContent>
            <Box>
              <img src={tick} alt="" width="94px" height="94px" />
            </Box>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              Thank you!
            </Typography>
            <Typography
              textAlign="left"
              mt={1}
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "24px",
              }}
            >
              We will call you back shortly.
            </Typography>

            {/* Verify OTP Button */}
            <Box textAlign="center" mt={5}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOk}
                sx={{
                  fontFamily: "Poppins",
                  display: "flex",
                  width: "100%",
                  height: "48px",
                  padding: "12px 24px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                  flexShrink: 0,
                  borderRadius: "12px",
                  border: "1px solid #B37839",
                  background: "#631041",
                }}
              >
                Okay
              </Button>
            </Box>
          </DialogContent>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ThankyouCard;
