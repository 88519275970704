import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./style.css";
import EnterYourDetailsModal from "../EnterYourDetailsModal/EnterYourDetailsModal";

// Custom Step Icon Component
interface StepIconProps {
  icon: number;
  active?: boolean;
  hover?: boolean; // Added hover
  onClick: () => void; // Added onClick
}

// Custom Step Icon Component
function CustomStepIcon({ icon, onClick, active, hover }: StepIconProps) {
  // You can replace with custom SVGs based on the icon number (1, 2, or 3)

  return (
    <div
    style={{
      position: "relative",
      width: "54px",
      height: "54px",
      cursor: "pointer",
      left:"34px",
      marginBottom:"20px"
     
    }}
    // onClick={onClick}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      style={{ position: "absolute", top: "14%", left: "14%",
          border:"4px solid #f7f1eb", borderRadius:"50%"
         }}
    >
      <circle
        cx="22"
        cy="22"
        r="21.5"
        // fill={active ? "#631041" : "#EFE7E3"}
        fill="#F8F1DE"        
        // stroke="#631041"
      />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <circle
        opacity="0.1"
        cx="30"
        cy="30"
        r="30"
        // fill={active ? "#631041" : "#FEFDF4"}
      />
    </svg>
    <Typography
      variant="h3"
      sx={{
        position: "absolute",
        top: "53%",
        left: "54%",
        transform: "translate(-50%, -50%)",
        // color: active ? "#FFF" : "#631041",
        color: "#631041",
        fontFamily: "Poppins",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "160%",
       
      }}
    >
      {icon}
    </Typography>
  </div>
  );
}

const steps = [
  {
    description: `Our Relationship Manager will reach out to understand your requirements.`,
  },
  {
    description:
      "The Relationship Manager will share the best service providers that match your needs. ",
  },
  {
    description: `Our Relationship Manager will negotiate and get the best price from the service providers you are interested in.`,
  },
  {
    description:
      "Get exclusive discounts on your wedding shopping from the top brands.",
  },
  {
    description: `The Relationship Manager will assist
you throughout your entire wedding
planning journey.`,
  },
];

interface VerticalarStepperProps {
  handleStep: (data:number)=>void,
  step: number; // Input number from another component
}

export const VerticalarStepper: React.FC<VerticalarStepperProps> = ({
  step,
  handleStep,
}) => {
  const [activeStep, setActiveStep] = React.useState(step);
  const [hoveredStep, setHoveredStep] = React.useState<number | null>(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    console.log("reset");
  };


  const handleStepClick = (step: number) => {
    handleStep(step);
    setActiveStep(step); // Navigate to clicked step
  };

  React.useEffect(() => {
setActiveStep(step)
  }, [step])
  
  return (
    <Box sx={{ maxWidth: "100%", height:"75vh" }} className="desk-verticular-step">
      {/* <Stepper activeStep={activeStep} orientation="vertical"> */}
        {steps.map((step, index) => (
          <Step key={step.description}>
            <StepLabel
              StepIconComponent={(props) => (
                <CustomStepIcon
                  icon={index + 1}
                  active={index === activeStep}
                  hover={index === hoveredStep} // Pass hover state
                  onClick={() => handleStepClick(index)}
                />
              )}
              onMouseEnter={() => setHoveredStep(index)} // Handle mouse enter
              onMouseLeave={() => setHoveredStep(null)} // Handle mouse leave
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  background:"#F8F1DE",
                  borderRadius:"16px",
                  padding:"8px 14px 8px 36px",
                  marginBottom:"16px",
                  // fontSize: index === activeStep ?"20px":"16px",
                  fontSize:"16px",
                  // fontWeight: index === activeStep ? 500 : 400,
                  fontWeight:500,
                  // marginLeft: { xs: "2%", lg: "5%" },
                }}
              >
                {step.description}
              </Typography>
            </StepLabel>
            {/* <StepContent>
            </StepContent> */}
          </Step>
        ))}
      {/* </Stepper> */}
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
