import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
  Box,
  TextField,
} from "@mui/material";
import bannerFinalImg from "../.././assets/images/bannerFinalImg.png";
import MobileBanner from "../MobileBanner/MobileBanner";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import OTPModal from "../OTPModal/OTPModal";
import { BannerBackgroundSvg } from "./BannerBackgroundSvg";
import { BannerSideFlower1 } from "./BannerSideFlower1";
import { BannerSideFlower2 } from "./BannerSideFlower2";
import { BannerSideFlower4 } from "./BannerSideFlower4";
import { BannerSideFlower3 } from "./BannerSideFlower3";
import { LocationSvg } from "./LocationSvg";
import "./style.css";
import { getEnquiryDataUsingMobileNumber } from "../../apollo/getEnquiryData";
import ThankyouCardForExisting from "../ThankyouCardForExisting/ThankyouCardForExisting";
import animation from "../../assets/images/animation.gif";
const BannerSection: React.FC = () => {
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [userName, setUserName] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");
  const [isThankyouCardForExisting, setIsThankyouCardForExisting] =
    useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .matches(/^[0-9]{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Mobile number is required"),
  });

  const handleSubmit = async (values: any, resetForm: () => void) => {
    const existingData = await getEnquiryDataUsingMobileNumber(
      values.mobile.toString()
    );
    if (existingData && existingData.is_otp_verified) {
      resetForm();
      setIsThankyouCardForExisting(true);
    } else {
      resetForm();
      setUserName(values.name);
      setUserMobileNumber(values.mobile.toString());
      setIsOtpModalOpen(true);
    }
  };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: {md:"100%", xl:"80%"},
          height: "650px",
          display: { xs: "none", md: "block" },
          margin:"auto"
        }}
      >
        {/* SVG Background */}
        <BannerBackgroundSvg />

        {/* Text Positioned Over the SVG */}
        <Box
          sx={{
            position: "absolute",
            top: "62%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            padding: "0 0",
            width: "100%",
            color: "#FFF",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "19px", sm: "22px", md: "28px", lg: "35px" },
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "50px" /* 147.059% */,
              // display: "flex",
              width: "100%",
              height: "60px",
              // justifyContent: "center",
            }}
          >
            Plan Your Dream Wedding with Our Expert Help
          </Typography>
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            className="banner-sub-title"
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal" /* 147.059% */,
              textAlign: "center",
              margin: "auto",
              // display: "flex",
              // justifyContent: "center"
            }}
          >
            Our relationship manager will guide your entire wedding planning
            journey, understand your needs, find the best service providers,
            negotiate to get the best price, and you can also get exclusive
            discounts on your wedding shopping from top brands
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: { md: "55%", lg: "45%", xl: "40%" },
              margin: "auto",
              justifyContent: "space-between",
              textAlign: "center",
              marginTop: { md: "2%", xl: "1%" },
            }}
          >
            <Box
              sx={{
                width: { md: "15%", lg: "20%" },
                background: "rgba(255, 255, 255, 0.40)",
                height: "1px",
                margin: "auto",
              }}
            ></Box>
            <Box
              sx={{
                width: { md: "5%", lg: "5%", margin: "auto", display: "flex" },
                justifyContent: "end",
              }}
            >
              <LocationSvg />
            </Box>
            <Box sx={{ width: { md: "60%", lg: "55%", xl: "50%" } }}>
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal" /* 147.059% */,
                  textAlign: "center",
                  width: "100%",
                  margin: "auto",
                  // display: "flex",
                  // justifyContent: "center"
                }}
              >
                We’re currently available only in Chennai
              </Typography>
            </Box>
            <Box
              sx={{
                width: { md: "15%", lg: "20%" },
                background: "rgba(255, 255, 255, 0.40)",
                height: "1px",
                margin: "auto",
              }}
            ></Box>
          </Box>
        </Box>
        {/* Card Component Positioned Over the SVG */}
        <Container
          sx={{
            position: "absolute",
            top: "48%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1088px",
          }}
        >
          <Formik
            initialValues={{ name: "", mobile: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values, resetForm);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form>
                <Card sx={{ maxWidth: 1088, margin: "auto" }}>
                  <Box
                    sx={{
                      backgroundPosition: "center",
                      height: 484,
                      borderRadius: "16px 16px 0px 0px",
                      background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 41.63%, rgba(0, 0, 0, 0.10) 49.3%, rgba(0, 0, 0, 0.40) 59.53%, rgba(0, 0, 0, 0.80) 74.87%, #000 92.77%), url(${bannerFinalImg}) lightgray -180px -154px / 129.766% 163.355% no-repeat`,
                    }}
                  />
                  <CardContent
                    sx={{
                      padding: "10px 20px 0px 20px",
                      height: "100px",
                      borderRadius: "0px 0px 16px 16px",
                      border: "2px solid #B37839",
                      background: "#FFFBF3",
                      boxShadow: "0px 8px 40px 0px rgba(224, 196, 117, 0.20)",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      size={{ xs: 12 }}
                    >
                      <Grid
                        size={{ xs: 3, sm: 3, md: 4 }}
                        sx={{ borderRight: "2px solid #CCC" }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            margin: "1% 0%",
                          }}
                        >
                          Name
                        </Typography>
                        <Field
                          name="name"
                          autoComplete="off"
                          as={TextField}
                          placeholder="Enter your name"
                          fullWidth
                          variant="standard"
                          onChange={handleChange}
                          value={values.name}
                          InputProps={{
                            disableUnderline: true, // Removes the underline
                            sx: {
                              color: "#000",
                              fontFamily: "Poppins",
                              fontSize: "16px",
                              opacity: 1,
                              fontWeight: "500",
                              backgroundColor: "transparent", // Prevent background color on focus
                              "&::placeholder": {
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                opacity: 1,
                                fontWeight: "500",
                              },
                              "&:focus-within": {
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                opacity: 1,
                                fontWeight: "500",
                                backgroundColor: "transparent", // Prevent blue background on focus
                              },
                              "&:hover": {
                                backgroundColor: "transparent", // Optional: prevents color change on hover
                              },
                            },
                          }}
                        />
                        {touched.name && errors.name ? (
                          <Typography
                            sx={{
                              color: "#EF2965",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            {errors.name}
                          </Typography>
                        ) : null}
                      </Grid>

                      {/* Mobile Number Field */}
                      <Grid
                        size={{ xs: 3, sm: 5, md: 4 }}
                        sx={{
                          borderRight: "2px solid #CCC",
                          paddingLeft: "1%",
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          sx={{
                            color: "#000",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            margin: "1% 0%",
                            lineHeight: "normal",
                          }}
                        >
                          Mobile Number
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              width: "10%",
                              display: "flex",
                              justifyContent: "flex-start",
                              marginTop: "1%",
                            }}
                          >
                            <Typography
                              component="span"
                              sx={{
                                fontWeight: "500",
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                opacity: 1,
                              }}
                            >
                              +91
                            </Typography>
                          </Box>
                          <Box
                            sx={{ width: { md: "87%", lg: "88%", xl: "88%" } }}
                          >
                            <Field
                              name="mobile"
                              as={TextField}
                              autoComplete="off"
                              placeholder="Enter your mobile number"
                              fullWidth
                              variant="standard"
                              onChange={(e: any) => {
                                const { value } = e.target;
                                if (/^\d*$/.test(value) && value.length <= 10) {
                                  setFieldValue("mobile", value); // Use setFieldValue to update form state
                                }
                              }}
                              type="number"
                              value={values.mobile}
                              InputProps={{
                                disableUnderline: true, // Removes the underline
                                sx: {
                                  color: "#000",
                                  fontFamily: "Poppins",
                                  fontSize: "16px",
                                  opacity: 1,
                                  fontWeight: "500",
                                  backgroundColor: "transparent", // Prevent background color on focus
                                  "&::placeholder": {
                                    color: "#000",
                                    fontFamily: "Poppins",
                                    fontSize: "20px",
                                    opacity: 1,
                                    fontWeight: "800",
                                  },
                                  "&:focus-within": {
                                    color: "#000",
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    opacity: 1,
                                    fontWeight: "500",
                                    backgroundColor: "transparent", // Prevent blue background on focus
                                  },
                                  "&:hover": {
                                    backgroundColor: "transparent", // Optional: prevents color change on hover
                                  },
                                  // Disable spinner in number input
                                  inputProps: {
                                    style: {
                                      MozAppearance: "textfield", // For Firefox
                                      appearance: "textfield", // For other browsers
                                    },
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Box>
                        {touched.mobile && errors.mobile ? (
                          <Typography
                            sx={{
                              color: "#EF2965",
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "normal",
                            }}
                          >
                            {errors.mobile}
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>

                      {/* Submit Button */}
                      <Grid
                        size={{ xs: 3, sm: 5, md: 4 }}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          height: "80px",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            width: { xs: "100%", sm: "90%", md: "85%" },
                            height: "52px",
                            borderRadius: "12px",
                            background: "#631041",
                            textTransform: "none",
                            color: "#FFF",
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "normal",
                          }}
                        >
                          Request call back
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                          >
                            <path
                              d="M9.5 6L15.5 12L9.5 18"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </Container>
        <Box
          sx={{ position: "absolute", top: "1%", left: "0%", opacity: "0.9" }}
        >
          <BannerSideFlower1 />
        </Box>
        <Box
          sx={{ position: "absolute", top: "1%", right: "0%", opacity: "0.2" }}
        >
          <BannerSideFlower2 />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0%",
            left: "0%",
            opacity: "0.1",
          }}
        >
          <BannerSideFlower3 />
        </Box>
        {/* <Box
          sx={{
            position: "absolute",
            bottom: "20%",
            left: "0%",
            opacity: "0.8",
            width: { md: "200px", lg: "150px", xl: "360px" },
            height: "360px",
            flexShrink: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "85%", zIndex:0 }}>
            <img src={animation} alt="animation" width="100%" />
          </Box>
        </Box> */}
        {/* <Box
          sx={{
            position: "absolute",
            bottom: "20%",
            right: "0%",
            opacity: "0.8",
            width: { md: "200px", lg: "150px", xl: "360px" },
            height: "360px",
            flexShrink: "0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "85%", zIndex:0 }}>
            <img src={animation} alt="animation" width="100%" />
          </Box>
        </Box> */}
        <Box
          sx={{
            position: "absolute",
            bottom: "0%",
            right: "0%",
            opacity: "0.1",
          }}
        >
          <BannerSideFlower4 />
        </Box>
      </Box>
      <MobileBanner />
      {isOtpModalOpen ? (
        <OTPModal
          open={isOtpModalOpen}
          onClose={() => setIsOtpModalOpen(false)}
          name={userName}
          mobileNumber={userMobileNumber}
        />
      ) : isThankyouCardForExisting ? (
        <ThankyouCardForExisting
          open={true}
          onClose={() => setIsThankyouCardForExisting(false)}
        />
      ) : null}
    </>
  );
};

export default BannerSection;
