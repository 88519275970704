import { gql } from "@apollo/client";
import client from "./apolloClient";

// Define the mutation as a constant
const CREATE_ENQUIRY_MUTATION = gql`
  mutation CreateOneMakemywedding_enquiries(
    $data: Makemywedding_enquiriesCreateInput
  ) {
    createOneMakemywedding_enquiries(data: $data) {
      id
      name
      phone
      is_otp_verified
      created_at
      updated_at
    }
  }
`;

// Reusable function to call the mutation
export const createEnquiry = async (
  name: string,
  phone: string,
  isOtpVerified: boolean | number
) => {
  try {
    // Define the mutation variables
    const variables = {
      data: {
        created_at: new Date(),
        is_otp_verified: isOtpVerified,
        name: name,
        phone: phone,
        updated_at: new Date(),
      },
    };

    // Execute the mutation using Apollo Client
    const result = await client.mutate({
      mutation: CREATE_ENQUIRY_MUTATION,
      variables,
    });

    // Return the result or data as needed
    return result.data.createOneMakemywedding_enquiries;
  } catch (error) {
    console.error("Error creating enquiry:", error);
    throw new Error("Failed to create enquiry");
  }
};
